<template>
  <v-dialog v-model="modalDialog" :wxid="$options.name" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <wx-btn-standard
        v-bind="attrs"
        v-on="on"
        :title="$t('dashboard.manualRejectsEntry.addRejectsBtnHoverTitle')"
        @click="openModalDialog"
        :class="btnCssClass"
        class="add-rejects-activator"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t("dashboard.manualRejectsEntry.addRejectsBtn") }}
      </wx-btn-standard>
    </template>

    <article class="v-dialog__content">
      <header>
        <v-btn icon large @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="mb-2 md-8 wx-typo-h1">{{ $t("dashboard.manualRejectsEntry.title") }}</h2>
      </header>

      <v-form :wxid="$options.name" ref="rejectsEntryForm" class="mt-6" lazy-validation>
        <fieldset>
          <v-row>
            <v-col cols="12" class="field-col">
              <wx-autocomplete
                v-model="rejectReasonDataSource"
                :items="availableRejectDataSources"
                item-text="text"
                item-value="value"
                return-object
                :rules="[() => validateRejectDataSource()]"
                :label="$t('dashboard.manualRejectsEntry.rejectStationLabel')"
                :placeholder="$t('dashboard.manualRejectsEntry.rejectStationLabel')"
              >
                <template #no-data>
                  <span class="wx-typo-sm ml-2">
                    {{ $t("dashboard.manualRejectsEntry.errors.noRejectStationForPU") }}
                  </span>
                </template>
              </wx-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="field-col">
              <production-run-selector
                v-model="applicableProductionRun"
                ref="productionRunSelector"
                :tooltip-text="$t('dashboard.manualRejectsEntry.productionRunSelectionTooltip')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="field-col">
              <wx-text-field
                v-model="quantity"
                ref="quantityInput"
                min="0"
                :rules="[() => validateQuantity()]"
                :label="$t('dashboard.manualRejectsEntry.quantityLabel')"
              />
            </v-col>
            <v-col cols="6" class="field-col">
              <wx-select
                v-model="unit"
                :items="availableUnits"
                :rules="[() => validateUnit()]"
                :label="$t('dashboard.manualRejectsEntry.unitLabel')"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="field-col">
              <wx-autocomplete
                v-model="rejectReasonObject"
                :items="availableReasonsFlattenTree"
                :rules="[() => validateRejectReason()]"
                :label="$t('dashboard.manualRejectsEntry.rejectionReasonLegend')"
                :placeholder="$t('dashboard.manualRejectsEntry.rejectionReasonPlaceholder')"
              >
                <template v-slot:item="{ item }">
                  <span class="wx-typo-sm">{{ item.name }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="wx-typo-sm">{{ item.nameWhenSelected }}</span>
                </template>
              </wx-autocomplete>
              <v-textarea
                v-model="comment"
                :label="$t('dashboard.manualRejectsEntry.optionalComment')"
                :counter="commentMaxLength"
                :maxlength="commentMaxLength"
                rows="2"
                auto-grow
                clearable
                filled
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="this.validationError">
            <v-alert type="error" icon="$alertIcon" outlined text>
              <p>{{ this.validationErrorMessage }}</p>
            </v-alert>
          </v-row>
        </fieldset>
        <fieldset class="form-footer-actions mt-4">
          <wx-btn-standard @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" outlined>
            {{ $t("common.cancel") }}
          </wx-btn-standard>
          <wx-btn-standard
            id="pendo-submit-reject-btn"
            @click="submit"
            :disabled="!this.submitButtonEnabled"
            :title="$t('common.formSubmitHoverTitle')"
            color="primary"
          >
            {{ $t("common.submit") }}
            <v-icon right>mdi-chevron-right</v-icon>
          </wx-btn-standard>
        </fieldset>
      </v-form>
    </article>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import ErrorHandling from "@/components/ErrorHandling";
import Validations from "@/components/validations";
import { DATE_FORMAT } from "@/store/TimeUtils";
import WxTextField from "@/components/ui/WxTextField.vue";
import WxSelect from "@/components/ui/WxSelect.vue";
import ProductionRunSelector from "@/components/dashboard/productionRun/ProductionRunSelector.vue";
import * as UnitChainUtils from "@/components/product/UnitChainUtils";

function isValidDecimalGreaterThan(valueAsString, minValueExcluded) {
  let value = Number.parseFloat(valueAsString);
  if (isNaN(value)) {
    return false;
  } else {
    if (value <= minValueExcluded) {
      return false;
    }
  }
  return true;
}

export default {
  name: "ManualRejectsEntry",
  components: {
    ProductionRunSelector,
    WxSelect,
    WxTextField,
    WxAutocomplete,
    WxBtnStandard,
  },
  data() {
    return {
      modalDialog: false,

      rejectReasonDataSource: null,
      applicableProductionRun: null,
      selectedDate: null,
      quantity: null,
      unit: null,
      availableUnits: [],
      rejectReasonObject: null,
      comment: null,
      commentMaxLength: Validations.rejectCommentMaxLength,

      submitButtonEnabled: false,

      validationError: false,
      validationErrorMessage: "",
    };
  },
  props: {
    btnCssClass: {
      type: String,
      default: null,
    },
  },
  watch: {
    async modalDialog() {
      if (this.modalDialog && this.activeFactory) {
        if (this.availableRejectDataSources && this.availableRejectDataSources.length === 1) {
          this.rejectReasonDataSource = this.availableRejectDataSources[0];
        }
        this.fetchAvailableRejectReasons()
          .then(() => (this.submitButtonEnabled = true))
          .catch(() => (this.submitButtonEnabled = true));
        await this.fetchActiveFactoryDataSources(this.activeFactory.id);
        this.$refs.productionRunSelector.initializeFields();
      }
    },
    unit() {
      this.$refs.quantityInput.validate();
    },
    availableRejectDataSources() {
      if (this.availableRejectDataSources && this.availableRejectDataSources.length === 1) {
        this.rejectReasonDataSource = this.availableRejectDataSources[0];
      }
    },
    applicableProductionRun() {
      if (this.applicableProductionRun) {
        this.selectedDate = DateTime.fromMillis(this.applicableProductionRun.end_time - 1).toFormat(DATE_FORMAT);
        this.setAvailableUnits();
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "date"]),
    ...mapGetters("dashboard", ["availableRejectReasons", "activeProductionUnit", "productionUnitProducts"]),
    ...mapGetters("devicesAdmin", ["activeFactoryDataSources"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    availableReasonsFlattenTree() {
      const selectorElements = [];
      for (let element of this.availableRejectReasons) {
        if (element.type === "category") {
          selectorElements.push({ divider: true });
          selectorElements.push({ header: element.name });
        } else {
          selectorElements.push({
            name: element.name,
            nameWhenSelected: element.name,
            id: element.id,
          });
        }
      }
      return selectorElements;
    },
    availableRejectDataSources() {
      if (this.activeFactoryDataSources && this.activeFactoryDataSources.length > 0) {
        const factoryRejectDataSources = this.activeFactoryDataSources.filter((ds) => ds.role === "reject");
        const rejectDataSources = factoryRejectDataSources.filter((ds) => {
          if (ds.production_unit_ids && ds.production_unit_ids.length > 0) {
            const found = ds.production_unit_ids.find((puId) => puId === this.activeProductionUnit.id);
            return found !== null && found !== undefined;
          } else {
            return false;
          }
        });
        return rejectDataSources.map((ds) => ({
          text: ds.description && ds.description.length > 0 ? ds.description : ds.id,
          value: ds.id,
          deviceId: ds.device_id,
        }));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchAvailableRejectReasons", "submitRejects"]),
    ...mapActions("operation", ["showOperationError"]),
    ...mapActions("devicesAdmin", ["fetchActiveFactoryDataSources"]),
    openModalDialog() {
      this.submitButtonEnabled = false;
      this.modalDialog = true;
    },
    closeModalDialog() {
      this.$refs.rejectsEntryForm.resetValidation();
      this.reset();
    },
    setAvailableUnits() {
      let sku = this.applicableProductionRun.sku;
      let product = this.productionUnitProducts.find((p) => p.sku === sku);
      let countUnits = UnitChainUtils.getApplicableCountUnits(product, this.activeProductionUnit);
      let nonCountUnits = UnitChainUtils.getApplicableNonCountUnits(product);
      this.availableUnits = countUnits.concat(nonCountUnits).map((unit) => UnitChainUtils.getUnitLabelAndValue(unit));
      let puConvertedUnitName = this.activeProductionUnit.converted_unit_name;
      this.unit = this.availableUnits.find((unit) => {
        switch (unit.value) {
          case "kg":
            return puConvertedUnitName === "kilogram";
          case "mt":
            return puConvertedUnitName === "ton";
          case "lb":
            return puConvertedUnitName === "pound";
          case "oz":
            return puConvertedUnitName === "ounce";
          case "m":
            return puConvertedUnitName === "meter";
          case "ft":
            return puConvertedUnitName === "feet";
          case "l":
            return puConvertedUnitName === "liter";
          default:
            return puConvertedUnitName === unit.value;
        }
      });
    },
    validateQuantity() {
      if (!this.quantity) {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidQuantity");
      }
      if (String(this.quantity).length > 0 && !isValidDecimalGreaterThan(this.quantity, 0.0)) {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidQuantity");
      }
      return true;
    },
    validateUnit() {
      if (!this.unit) {
        return this.$t("dashboard.manualRejectsEntry.errors.noUnit");
      }
      this.validationError = false;
      this.validationErrorMessage = "";
      return true;
    },
    validateRejectReason() {
      if (!this.rejectReasonObject) {
        return this.$t("dashboard.manualRejectsEntry.errors.noRejectReason");
      }
      return true;
    },
    resetDateValidations() {
      this.validationError = false;
      this.validationErrorMessage = "";
    },
    validateRejectDataSource() {
      if (this.rejectReasonDataSource) {
        return true;
      } else {
        return this.$t("dashboard.manualRejectsEntry.errors.noRejectStation");
      }
    },
    submit() {
      this.resetDateValidations();
      let isValid = this.$refs.rejectsEntryForm.validate();
      if (isValid) {
        let rejectDate;
        if (this.applicableProductionRun.end_millis_utc) {
          rejectDate = DateTime.fromMillis(this.applicableProductionRun.end_millis_utc).minus({ second: 1 });
        } else {
          rejectDate = DateTime.now();
        }
        const rejectFormattedDate = rejectDate.toFormat("yyyy-LL-dd'T'HH:mm:ssZZ");

        let payload = {
          datasource_id: this.rejectReasonDataSource.value,
          timestamp: rejectFormattedDate,
          value: this.quantity,
          unit: this.unit.value,
          reason: {
            id: this.rejectReasonObject.id,
            comment: this.comment,
          },
          production_run: {
            sku: this.applicableProductionRun.sku,
            work_order: this.applicableProductionRun.work_order_label,
            lot: this.applicableProductionRun.lot_label,
          },
        };
        const deviceId = this.rejectReasonDataSource.deviceId;
        this.submitRejects({ deviceId: deviceId, rejectPayload: payload })
          .then(() => this.closeModalDialog())
          .catch((error) => this.handleRejectError(error.response));
      }
    },
    handleRejectError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code, args, message) {
      if (code === "RS_MANUAL_REJECT_CREATION_POST_10011") {
        const regex = /The provided reject unit '(.*)' is not compatible with product target unit '(.*)' for SKU '(.*)'/gm;
        let matches = regex.exec(message);
        let unit = !!matches && matches.length >= 4 ? matches[1] : "";
        let targetUnit = !!matches && matches.length >= 4 ? matches[2] : "";
        let sku = !!matches && matches.length >= 4 ? matches[3] : "";
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.unitIncompatible", {
          unit: unit,
          targetUnit: targetUnit,
          sku: sku,
        });
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10012") {
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.outOfProduction");
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10015") {
        const regex = /The rejected quantity unit is of type '(.*)', but no target unit configured for sku '(.*)'/gm;
        let matches = regex.exec(message);
        let unit = !!matches && matches.length >= 3 ? matches[1] : "";
        let sku = !!matches && matches.length >= 3 ? matches[2] : "";
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.noTargetUnit", {
          unit: unit,
          sku: sku,
        });
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10017") {
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.noSku");
        return null;
      }
      return this.$t("common.errors.default", { code: code });
    },
    reset() {
      this.rejectReasonDataSource = null;
      this.applicableProductionRun = null;
      this.selectedDate = null;

      this.quantity = null;
      this.unit = null;
      this.rejectReasonObject = null;
      this.comment = null;
      this.submitButtonEnabled = false;
      this.modalDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 600px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }

    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;

        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
