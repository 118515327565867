<template>
  <v-form :wxid="$options.name" ref="productionRunSelectionRun" lazy-validation>
    <section class="prod-run-selector">
      <fieldset class="inline-fields">
        <v-row>
          <v-col cols="8" sm="8" class="field-col">
            <span class="production-run-navigation-label">
              {{ $t("dashboard.conversionFactorOverride.productionRunNavigationLabel") }}
              <wx-contextualized-help v-if="tooltipText" :tooltip-text="tooltipText" />
            </span>
          </v-col>
          <v-spacer />
          <v-col cols="3" sm="3" class="field-col">
            <wx-btn-standard
              :disabled="!previousProductionRunEnabled"
              color="secondary"
              class="production-run-arrow"
              dense
              @click="selectPreviousProductionRun()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </wx-btn-standard>
            <wx-btn-standard
              :disabled="!nextProductionRunEnabled"
              color="secondary"
              class="production-run-arrow ml-1"
              dense
              @click="selectNextProductionRun()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </wx-btn-standard>
          </v-col>
        </v-row>
        <v-row class="mt-2 ml-0 mr-1 production-run-properties">
          <v-col cols="12">
            <!-- SKU -->
            <div class="flex-column flex-sm-row align-left production-run-property wx-typo-sm text-truncate">
              <span class="font-weight-light mr-2">{{ $t("dashboard.conversionFactorOverride.skuLabel") }}:</span>
              <span class="production-run-property-value">{{ currentlySelectedProductionRunSku }}</span>
            </div>
            <!-- Product Name -->
            <div class="flex-column flex-sm-row align-left production-run-property wx-typo-sm text-truncate">
              <span class="font-weight-light mr-2">{{ $t("dashboard.conversionFactorOverride.nameLabel") }}: </span>
              <span class="production-run-property-value">{{ currentlySelectedProductionRunProductName }}</span>
            </div>
            <!-- Work Order -->
            <div class="flex-column flex-sm-row align-left production-run-property wx-typo-sm text-truncate">
              <span class="font-weight-light mr-2">
                {{ $t("dashboard.conversionFactorOverride.workOrderLabel") }}:
              </span>
              <span class="production-run-property-value">{{ currentlySelectedProductionRunWorkOrder }}</span>
            </div>
            <!-- Lot -->
            <div class="flex-column flex-sm-row align-left wx-typo-sm text-truncate">
              <span class="font-weight-light mr-2"> {{ $t("dashboard.conversionFactorOverride.lotLabel") }}: </span>
              <span class="production-run-property-value">{{ currentlySelectedProductionRunLot }}</span>
            </div>
            <!-- Production Run period -->
            <div class="flex-column flex-sm-row align-left wx-typo-sm mt-3">
              <span class="font-weight-light mr-2">
                {{ $t("dashboard.conversionFactorOverride.productionRunPeriodLabel") }}:
              </span>
              <span class="production-run-property-value">{{ currentlySelectedProductionRunTimespan }}</span>
            </div>
            <!-- Product Conversion Factor -->
            <div v-if="showProductConversionFactor" class="flex-column flex-sm-row align-left wx-typo-sm mt-4">
              <span class="font-weight-light mr-2">
                {{ $t("dashboard.conversionFactorOverride.productConversionFactor") }}:
              </span>
              <span class="production-run-property-value">{{ productConversionFactor }}</span>
            </div>
          </v-col>
        </v-row>
      </fieldset>
    </section>
  </v-form>
</template>

<script>
import { dash } from "@/helpers";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT_NO_SECONDS } from "@/store/TimeUtils";
import { mapGetters } from "vuex";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";

export default {
  name: "ProductionRunSelector",
  components: { WxContextualizedHelp, WxBtnStandard },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    tooltipText: {
      type: String,
      default: () => null,
    },
    showProductConversionFactor: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedProductionRun: this.value,
      selectedProductionRunIndex: null,
      previousProductionRunEnabled: true,
      nextProductionRunEnabled: false,
      productConversionFactor: null,
    };
  },
  watch: {
    value() {
      this.selectedProductionRun = this.value;
    },
  },
  computed: {
    ...mapGetters("dashboard", ["productionUnitProducts", "activeProductionUnit", "productionRunCoverage"]),
    ...mapGetters("navigation", ["activeFactory"]),
    currentlySelectedProductionRunSku() {
      if (this.selectedProductionRun && this.selectedProductionRun.sku) {
        return this.selectedProductionRun.sku;
      }
      return dash;
    },
    currentlySelectedProductionRunProductName() {
      if (this.selectedProductionRun && this.selectedProductionRun.product_name) {
        return this.selectedProductionRun.product_name;
      }
      return dash;
    },
    currentlySelectedProductionRunWorkOrder() {
      if (this.selectedProductionRun && this.selectedProductionRun.work_order_label) {
        return this.selectedProductionRun.work_order_label;
      }
      return dash;
    },
    currentlySelectedProductionRunLot() {
      if (this.selectedProductionRun && this.selectedProductionRun.lot_label) {
        return this.selectedProductionRun.lot_label;
      }
      return dash;
    },
    currentlySelectedProductionRunTimespan() {
      if (this.selectedProductionRun) {
        const s = DateTime.fromISO(this.selectedProductionRun.start_date).toFormat(DATE_TIME_FORMAT_NO_SECONDS);
        const e = this.selectedProductionRun.end_date
          ? DateTime.fromISO(this.selectedProductionRun.end_date).toFormat(DATE_TIME_FORMAT_NO_SECONDS)
          : this.$t("common.live");
        return `${s} ${dash} ${e}`;
      }
      return dash;
    },
  },
  methods: {
    initializeFields() {
      this.$refs.productionRunSelectionRun.resetValidation();
      if (this.value) {
        const prodRun = this.findProductionRun();
        this.selectedProductionRun = prodRun.productionRun;
        this.selectedProductionRunIndex = prodRun.productionRunIndex;
      } else {
        this.selectedProductionRunIndex = 0; // First index of the list (current production run)
        this.selectedProductionRun = this.productionRunCoverage[this.selectedProductionRunIndex];
      }
      this.initializeProductionRunNavigation();
      this.setProductConversionFactor();
      this.$emit("input", this.selectedProductionRun);
    },
    findProductionRun() {
      const timestamp = this.selectedProductionRun.original_start;
      let productionRunIndex = this.productionRunCoverage.length - 1;
      const productionRun = this.productionRunCoverage.find((pr, index) => {
        let productionRunStart = DateTime.fromISO(pr.start_date).toMillis();
        let productionRunEnd = pr.end_date ? DateTime.fromISO(pr.end_date).toMillis() : DateTime.now().toMillis();
        if (productionRunStart <= timestamp && productionRunEnd >= timestamp) {
          productionRunIndex = index;
          return true;
        }
        return false;
      });
      return {
        productionRun: productionRun,
        productionRunIndex: productionRunIndex,
      };
    },
    initializeProductionRunNavigation() {
      this.previousProductionRunEnabled = this.selectedProductionRunIndex < this.productionRunCoverage.length - 1;
      this.nextProductionRunEnabled = this.selectedProductionRunIndex > 0;
    },
    selectPreviousProductionRun() {
      this.$refs.productionRunSelectionRun.resetValidation();
      this.selectedProductionRunIndex++;
      this.selectedProductionRun = this.productionRunCoverage[this.selectedProductionRunIndex];
      this.initializeProductionRunNavigation();
      this.setProductConversionFactor();
      this.$emit("input", this.selectedProductionRun);
    },
    selectNextProductionRun() {
      this.$refs.productionRunSelectionRun.resetValidation();
      this.selectedProductionRunIndex--;
      this.selectedProductionRun = this.productionRunCoverage[this.selectedProductionRunIndex];
      this.initializeProductionRunNavigation();
      this.setProductConversionFactor();
      this.$emit("input", this.selectedProductionRun);
    },
    setProductConversionFactor() {
      if (this.showProductConversionFactor && this.selectedProductionRun) {
        this.productConversionFactor = this.findProductConversionFactor(this.selectedProductionRun.sku);
      }
    },
    findProductConversionFactor(sku) {
      let product = this.productionUnitProducts.find((p) => p.sku === sku);
      if (product) {
        const puAssociation = product.associated_production_units.find(
          (a) => a.production_unit_id === this.activeProductionUnit.id,
        );
        if (puAssociation) {
          return puAssociation.conversion_factor;
        }
      }
      return "1:1";
    },
  },
};
</script>

<style lang="scss" scoped>
// matching the style of `ui/WxContextualizedHelp.vue`
.wx-contextualized-help {
  &__activator {
    font-size: var(--font-size-h3);
  }
}

.inverted-theme-card {
  &.v-card {
    background-color: var(--color-flat-panel-theme);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card__text {
      color: var(--color-text-theme);
    }
  }
}

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 500px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
.prod-run-selector {
  .inline-fields {
    width: 100%;

    .production-run-properties {
      border: 1px solid var(--color-text-subtle-theme);
      border-bottom-width: 2px;
      border-radius: var(--border-radius-sm);
    }
  }
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.production-run-navigation-label {
  font-size: var(--font-size-h3);
  line-height: 1.5;
  font-weight: 400;
}
.production-run-arrow {
  max-height: 24px;
}
.production-run-property {
  max-width: 475px;
}
.production-run-property-value {
  color: var(--color-primary);
}
</style>
