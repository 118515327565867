<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled, unfocused: isTileUnfocused }"
    class="tile"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.totalWeight')" class="primary-title">
        {{ $t("tiles.totalWeight") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p v-if="valueUnitLabel" class="value-sub-text__unit label">{{ valueUnitLabel }}</p>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import helpers, { dash } from "@/helpers";
import { convertKilogramTo, getUnitLabel } from "@/UnitUtils";

export default {
  name: "TileTotalWeight",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["totalWeightQuantity", "activeDashboardPreferencesTiles"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    ...mapGetters("tiles", ["selectedTileIndex"]),
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    tileUnit() {
      if (!this.isTileAuthorized()) return null;
      const tile = this.activeDashboardPreferencesTiles[this.tileIndex];
      return tile.tile === "total_weight" ? tile.config?.product_unit : null;
    },
    valueUnitLabel() {
      return getUnitLabel(this.tileUnit);
    },
    primaryValue() {
      if (this.totalWeightQuantity == null || !this.isTileAuthorized() || !this.tileUnit) return dash;
      let weight = convertKilogramTo(this.totalWeightQuantity, this.tileUnit);
      return helpers.getRoundedValueOrDash(weight, this.isTileAuthorized(), 1);
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.totalWeight.requiredFeature);
    },
  },
};
</script>
