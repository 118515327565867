<template>
  <v-form :wxid="$options.name" ref="conversionFactorOverrideForm" class="mt-6" lazy-validation>
    <section class="cf-override-row">
      <fieldset class="inline-fields">
        <v-row>
          <!-- - - - - - - - - - - -     Production Run Selector      - - - - - - - - - - - - - -->
          <v-col cols="12" class="field-col">
            <production-run-selector v-model="applicableProductionRun" show-product-conversion-factor ref="productionRunSelector" />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <!-- - - - - - - - - - - -     Conversion Factor Override      - - - - - - - - - - - - - -->
          <v-col cols="6" class="field-col">
            <wx-text-field
              v-model.number="conversionFactorCountOverride"
              :label="$t('product.details.conversionFactorCapturedQuantity') + ' *'"
              :suffix="$t('product.details.conversionFactorCount')"
              :rules="[() => validateConversionFactorCount()]"
              type="number"
            />
          </v-col>
          <v-col cols="6" class="field-col">
            <wx-text-field
              v-model.number="conversionFactorQuantityOverride"
              class="equal-before"
              :label="$t('product.details.conversionFactorRealQuantity') + ' *'"
              :suffix="getConvertedUnitName()"
              :rules="[() => validateConversionFactorRealQuantity()]"
              type="number"
            />
          </v-col>
          <v-col cols="6" class="field-col">
            <wx-simple-calendar-date-picker
              v-if="effectiveStartDate"
              v-model="effectiveStartDate"
              :label="$t('dashboard.productionRun.effectiveStartDate')"
              :timezone="getTimezone()"
              :limit-past-number-of-days="30"
              :error-messages="effectiveStartDateMessages"
              @onDateChange="forceDateValidation"
            />
          </v-col>
          <v-col cols="6" class="field-col">
            <wx-time-picker
              v-model="effectiveStartTime"
              :title="$t('dashboard.productionRun.effectiveStartTime')"
              :error-messages="effectiveStartTimeMessages"
              :show-prepend-icon="false"
              @updated="forceDateValidation"
            />
          </v-col>
        </v-row>
      </fieldset>
    </section>

    <fieldset class="form-footer-actions mt-4">
      <wx-btn-standard
        id="pendo-cancel-conversion-factor-override-btn"
        @click="emitClose"
        :title="$t('common.dialogFormCancelHoverTitle')"
        outlined
      >
        {{ $t("common.cancel") }}
      </wx-btn-standard>
      <wx-btn-standard
        id="pendo-submit-conversion-factor-override-btn"
        @click="emitSubmit"
        :title="$t('common.formSubmitHoverTitle')"
        color="primary"
      >
        {{ $t("common.submit") }}
      </wx-btn-standard>
    </fieldset>
  </v-form>
</template>

<script>
import * as TimeUtils from "@/store/TimeUtils";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT_NO_SECONDS } from "@/store/TimeUtils";
import moment from "moment-timezone";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import Validations from "@/components/validations";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxSimpleCalendarDatePicker from "@/components/ui/WxSimpleCalendarDatePicker.vue";
import WxTextField from "@/components/ui/WxTextField.vue";
import WxTimePicker from "@/components/ui/WxTimePicker.vue";
import ProductionRunSelector from "@/components/dashboard/productionRun/ProductionRunSelector.vue";

export default {
  name: "ConversionFactorOverrideForm",
  components: {
    ProductionRunSelector,
    WxTextField,
    WxBtnStandard,
    WxTimePicker,
    WxSimpleCalendarDatePicker,
  },
  props: {
    initialConversionFactorOverride: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      applicableProductionRun: null,
      conversionFactorCountOverride: null,
      conversionFactorQuantityOverride: null,
      productConversionFactor: null,
      effectiveStart: null,
      effectiveStartDate: null,
      effectiveStartDateMessages: [],
      effectiveStartTime: "00:00",
      effectiveStartTimeMessages: [],
    };
  },
  watch: {
    initialConversionFactorOverride(current, previous) {
      if (current.event_id !== previous.event_id) {
        this.initializeFields();
      }
    },
  },
  computed: {
    ...mapGetters("dashboard", ["productionUnitProducts", "activeProductionUnit", "productionRunCoverage"]),
    ...mapGetters("navigation", ["activeFactory"]),
  },
  methods: {
    initializeFields() {
      this.$refs.productionRunSelector.initializeFields();
      if (this.initialConversionFactorOverride) {
        this.effectiveStart = DateTime.fromMillis(this.initialConversionFactorOverride.original_start);
        this.setConversionFactorOverrideCounters(this.initialConversionFactorOverride.conversion_factor);
      } else {
        this.effectiveStart = DateTime.now();
      }
      if (this.applicableProductionRun) {
        this.productConversionFactor = this.findProductConversionFactor(this.applicableProductionRun.sku);
      }
      this.initializeStartDateTime(this.effectiveStart.toMillis());
    },
    setConversionFactorOverrideCounters(conversionFactorString) {
      let separatorIndex = conversionFactorString.indexOf(":");
      this.conversionFactorCountOverride = conversionFactorString.slice(0, separatorIndex);
      this.conversionFactorQuantityOverride = conversionFactorString.slice(separatorIndex + 1);
    },
    findProductConversionFactor(sku) {
      let product = this.productionUnitProducts.find((p) => p.sku === sku);
      if (product) {
        const puAssociation = product.associated_production_units.find(
          (a) => a.production_unit_id === this.activeProductionUnit.id,
        );
        if (puAssociation) {
          return puAssociation.conversion_factor;
        }
      }
      return "1:1";
    },
    initializeStartDateTime(effectiveStart) {
      this.effectiveStartDate = this.getDefaultEffectiveStartDate(effectiveStart);
      this.effectiveStartTime = this.getDefaultEffectiveStartTime(effectiveStart);
    },
    getDefaultEffectiveStartDate(millisUtc) {
      if (!millisUtc) return TimeUtils.getTodayDate(this.getTimezone());
      return TimeUtils.getDateFromMillis(millisUtc, this.getTimezone());
    },
    getDefaultEffectiveStartTime(millisUtc) {
      if (!millisUtc) return TimeUtils.getCurrentTime(this.getTimezone());
      return TimeUtils.getTimeFromMillis(millisUtc, this.getTimezone());
    },
    getTimezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    getConvertedUnitName() {
      let convertedUnitName = this.activeProductionUnit.converted_unit_name;
      return convertedUnitName ? convertedUnitName : ProductionUnitService.getUnitName(null);
    },
    validateConversionFactorCount() {
      if (
        isNaN(this.conversionFactorCountOverride) ||
        this.conversionFactorCountOverride <= 0 ||
        this.conversionFactorCountOverride > 9999999999 ||
        !Validations.isProductConversionFactorValid(this.conversionFactorCountOverride)
      ) {
        return this.$t("product.errors.invalidConversionFactorCapturedQuantity");
      }
      return true;
    },
    validateConversionFactorRealQuantity() {
      if (
        isNaN(this.conversionFactorQuantityOverride) ||
        this.conversionFactorQuantityOverride <= 0 ||
        this.conversionFactorQuantityOverride > 9999999999 ||
        !Validations.isProductConversionFactorValid(this.conversionFactorQuantityOverride)
      ) {
        return this.$t("product.errors.invalidConversionFactorRealQuantity");
      }
      return true;
    },
    forceDateValidation() {
      this.resetDateValidations();
      this.validateAndSetEffectiveDates();
    },
    resetDateValidations() {
      this.effectiveStartDateMessages = [];
      this.effectiveStartTimeMessages = [];
    },
    validateAndSetEffectiveDates() {
      this.resetDateValidations();

      let startDate;
      let startTime;
      // -------------- Validate the date input --------------
      if (this.effectiveStartDate) {
        startDate = DateTime.fromFormat(this.effectiveStartDate, TimeUtils.DATE_FORMAT);
        if (!startDate.isValid) {
          this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.invalidDate")];
          return false;
        }
      } else {
        this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.dateMissing")];
        return false;
      }
      // -------------- Validate the time input --------------
      if (this.effectiveStartTime) {
        startTime = DateTime.fromFormat(this.effectiveStartTime, TimeUtils.TIME_FORMAT_HH_MM);
        if (!startTime.isValid) {
          this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.invalidTime")];
          return false;
        }
      } else {
        this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.timeMissing")];
        return false;
      }

      // At this point, the Start date & time are valid
      let selectedOverrideStart = startDate.set({
        hour: startTime.hour,
        minute: startTime.minute,
        second: 0,
        millisecond: 0,
      });

      // -------------- Validate if within selected production run --------------
      let productionRunStartDateTime = DateTime.fromISO(this.applicableProductionRun.start_date);
      let productionRunEndDateTime = this.applicableProductionRun.end_date
        ? DateTime.fromISO(this.applicableProductionRun.end_date)
        : DateTime.now();
      const selectedOverrideStartMillis = selectedOverrideStart.toMillis();
      const isWithinSelectedProductionRunTimespan =
        selectedOverrideStartMillis >= productionRunStartDateTime.toMillis() &&
        selectedOverrideStartMillis < productionRunEndDateTime.toMillis();

      if (isWithinSelectedProductionRunTimespan) {
        return true;
      } else {
        const s = productionRunStartDateTime.toFormat(DATE_TIME_FORMAT_NO_SECONDS);
        const e = productionRunEndDateTime.minus({ minute: 1 }).toFormat(DATE_TIME_FORMAT_NO_SECONDS);
        this.effectiveStartDateMessages = [
          this.$t("dashboard.conversionFactorOverride.errors.invalidDate", { start: s, end: e }),
        ];
        this.effectiveStartTimeMessages = [
          this.$t("dashboard.conversionFactorOverride.errors.invalidDate", { start: s, end: e }),
        ];
        return false;
      }
    },
    emitClose() {
      this.$refs.conversionFactorOverrideForm.resetValidation();
      this.resetDateValidations();
      this.$emit("close");
    },
    emitSubmit() {
      // make sure the form is valid before submitting
      if (!this.$refs.conversionFactorOverrideForm.validate() || !this.validateAndSetEffectiveDates()) return;

      let startTime = DateTime.fromFormat(this.effectiveStartTime, TimeUtils.TIME_FORMAT_HH_MM);
      let startDateInFactoryTimezone = DateTime.fromFormat(this.effectiveStartDate, TimeUtils.DATE_FORMAT)
        .set({
          hour: startTime.hour,
          minute: startTime.minute,
          second: 0,
          millisecond: 0,
        })
        .setZone(this.activeFactory.timezone)
        .toISO();

      const submission = {
        eventId: this.initialConversionFactorOverride ? this.initialConversionFactorOverride.event_id : null,
        startTime: startDateInFactoryTimezone,
        conversionFactor: this.conversionFactorCountOverride + ":" + this.conversionFactorQuantityOverride,
      };
      this.$emit("submit", submission);
    },
    reset() {
      this.$refs.conversionFactorOverrideForm.resetValidation();
      this.resetDateValidations();
      this.initializeFields();
    },
  },
  mounted() {
    this.$refs.conversionFactorOverrideForm.resetValidation();
    this.resetDateValidations();
    this.initializeFields();
  },
};
</script>

<style lang="scss" scoped>
// matching the style of `ui/WxContextualizedHelp.vue`
.wx-contextualized-help {
  &__activator {
    font-size: var(--font-size-h3);
  }
}

.inverted-theme-card {
  &.v-card {
    background-color: var(--color-flat-panel-theme);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card__text {
      color: var(--color-text-theme);
    }
  }
}

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 500px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
.cf-override-row {
  .inline-fields {
    width: 100%;

    .production-run-properties {
      border: 1px solid var(--color-text-subtle-theme);
      border-bottom-width: 2px;
      border-radius: var(--border-radius-sm);
    }
  }
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.production-run-navigation-label {
  font-size: var(--font-size-h2);
  line-height: 1.2;
  font-weight: 400;
}
.production-run-property {
  max-width: 475px;
}
</style>
